const devMenus = [
  {
    title: "发电数据分析",
    children: [
      {
        name: "overview",
        title: "总览",
        path: "/overview",
      },
      {
        name: "analyse_station",
        title: "光伏站分析",
        path: "/analysis/station",
      },
      {
        name: "analyse_area",
        title: "片区分析",
        path: "/analysis/area",
      },
      {
        name: "analyse_inverter",
        title: "逆变器分析",
        path: "/analysis/inverter",
      },
      {
        name: "analyse_series",
        title: "组串分析",
        path: "/analysis/series",
      },
    ],
  },
  {
    title: "高压设备",
    children: [
      {
        name: "hve-in",
        title: "光伏进线分析",
        path: "/analysis-hvd/in",
      },
      {
        name: "hve-out",
        title: "并网出线分析",
        path: "/analysis-hvd/out",
      },
      {
        name: "hvd-svg",
        title: "SVG分析",
        path: "/analysis-hvd/svg",
      },
      {
        name: "hvd-transformer",
        title: "箱变分析",
        path: "/analysis-hvd/transformer",
      },
    ],
  },
  {
    title: "对比分析",
    children: [
      {
        name: "compares-station",
        title: "光伏站对比分析",
        path: "/compares/station",
      },
      {
        name: "compares-inverter",
        title: "逆变器对比分析",
        path: "/compares/inverter",
      },
      {
        name: "compares-serial",
        title: "组串对比分析",
        path: "/compares/serial",
      },
    ],
  },
  {
    title: "光伏站数据监控",
    children: [
      {
        name: "monitoring-powerside",
        title: "发电侧数据监控",
        path: "/monitoring/powerside",
      },
    ],
  },
  {
    title: "光伏二期",
    children: [
      {
        name: "video-monitor",
        title: "视频监控",
        path: "/video-monitor",
      },
      {
        name: "performance-station",
        title: "光伏站绩效",
        path: "/performance/station",
      },
      {
        name: "performance-system",
        title: "系统绩效分析",
        path: "/performance/system",
      },
      {
        name: "performance-inverter",
        title: "逆变器绩效",
        path: "/performance/inverter",
      },
      {
        name: "performance-assessments",
        title: "光伏健康评估",
        path: "/performance/assessments",
      },
      {
        name: "performance-dispersion-setting",
        title: "离散度分析设置",
        path: "/performance/dispersion-setting",
      },
      {
        name: "performance-dispersion-serial",
        title: "组串离散率分析",
        path: "/performance/dispersion-serial",
      },
    ],
  },
  {
    title: "光伏三期",
    children: [
      {
        name: "neec-statistic",
        title: "非经济性能源统计",
        path: "/neec/statistics",
      },
      {
        name: "alarm-statistic",
        title: "光伏报警统计",
        path: "/alarm/statistics",
      },
      {
        name: "operate-overview",
        title: "站点级运营概览",
        path: "/operate-overview",
      },
      {
        name: "income-analysis",
        title: "光伏收益统计分析",
        path: "/income-analysis",
      },
    ],
  },
  {
    title: "光伏(近期)",
    children: [
      {
        name: "diagnosis",
        title: "光伏诊断分析",
        path: "/diagnosis",
      },
      {
        name: "CER",
        title: "碳减排总览",
        path: "/cer",
      },
      {
        name: "CERHTML",
        title: "碳减排总览",
        path: "/cer-html",
      },
    ],
  },
  {
    title: "光伏(最新)",
    children: [
      {
        name: "station-analysis",
        title: "光伏数据分析",
        path: "/station-analysis",
      },
      {
        name: "hvc-analysis",
        title: "高压设备分析",
        path: "/hvc-analysis",
      },
      {
        name: "actual-time-board-v2",
        title: "实时数据看板v2",
        path: "/actual-time-board-v2",
      },
      {
        name: "string-dispersion",
        title: "组串离散率分析",
        path: "/string-dispersion",
      },
    ],
  },
  {
    title: "机器人控制",
    children: [
      {
        name: "robotControl-panel",
        title: "机器人控制页面",
        path: "/robotControl/panel",
      },
      {
        name: "robotControl-operationLog",
        title: "机器人控制日志",
        path: "/robotControl/operationLog",
      },
    ],
  },
];

const { protocol, host, pathname } = window.location;
const localBaseUrl = `${protocol}//${host}`;
export const devMenuApply = (items: any) => {
  if (!["development", "preview"].includes(import.meta.env.MODE)) return;
  items.unshift({
    currentId: "dev",
    level: 1,
    name: "开发支持",
    openType: 0,
    parentId: "0",
    rootId: "1",
    transitPagePath: null,
    weighting: 0,
    children: devMenus.map((g, gidx) => ({
      currentId: `dev${gidx}`,
      level: 2,
      name: g.title,
      openType: 0,
      parentId: "dev",
      rootId: "dev",
      transitPagePath: null,
      weighting: 0,
      children: g.children.map((d, idx) => ({
        currentId: d.name,
        level: 3,
        name: d.title,
        openType: 0,
        parentId: `dev${gidx}`,
        rootId: "dev",
        transitPagePath: `${localBaseUrl}${d.path}`,
        weighting: 0,
        children: [],
      })),
    })),
  });
};

export const getDevMenus = () => {
  if (!["development", "preview"].includes(import.meta.env.MODE)) return;
  return {
    currentId: "dev",
    level: 1,
    name: "开发支持",
    openType: 0,
    parentId: "0",
    rootId: "1",
    transitPagePath: null,
    weighting: 0,
    children: devMenus.map((g, gidx) => ({
      currentId: `dev${gidx}`,
      level: 2,
      name: g.title,
      openType: 0,
      parentId: "dev",
      rootId: "dev",
      transitPagePath: null,
      weighting: 0,
      children: g.children.map((d, idx) => ({
        currentId: d.name,
        level: 3,
        name: d.title,
        openType: 0,
        parentId: `dev${gidx}`,
        rootId: "dev",
        transitPagePath: `${localBaseUrl}${d.path}`,
        weighting: 0,
        children: [],
      })),
    })),
  };
};
