import request from "@/utils/request";
import { SerialString, TimeValueResult } from "./@types";
import { dateRangeDec } from "@/utils/date";

export const list = (
  stationId: number,
  areaId: number,
  combinerBoxId: number,
  inverterId: number,
): Promise<SerialString[]> => {
  const params = { stationId, areaId, combinerBoxId, inverterId };
  return request.get("common/list/string", { params });
};

/**
 * @name 电压
 */
export const fetchVoltage = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("data/string/voltage", { params });
};

/**
 * @name 电流
 */
export const fetchElectricity = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<{ timeJumpList: string[]; itemVOList: TimeValueResult[] }> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("data/string/electricity", { params });
};
