<template>
  <ChartPower :data="data" :mode="props.query.dateType" v-loading="loading" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import debounce from "@/utils/debounce";
import { fetchPower } from "@/api/area";
import { TimeValueResult } from "@/api/@types";

interface ChartProps {
  query: {
    stationId: number;
    areaId: number;
    dateType: "hour" | "day" | "month";
    dateRange: [string, string];
  };
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    stationId: 0,
    areaId: 0,
    dateType: "hour",
    dateRange: ["", ""],
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>();
watch(
  () => props.query,
  () => {
    const query = props.query;
    if (
      !query.stationId ||
      !query.areaId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = [];
      return;
    }
    loading.value = true;
    debounce("chart-area-power", () => {
      fetchPower(
        query.stationId,
        query.areaId,
        query.dateRange,
        query.dateType,
      ).then(list => {
        data.value = list;
        loading.value = false;
      });
    });
  },
  { immediate: true },
);
</script>
