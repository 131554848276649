<template>
  <div :class="[containerClass, customClass]" :style="customStyle">
    <div class="row">
      <slot>
        <div class="slot-item">
          <div class="label">默认标签</div>
          <div class="value">默认值</div>
        </div>
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";

interface Props {
  columns?: number; // 列数 (1, 2, 3)
  showDashedBorder?: boolean; // 是否展示虚线边框
  customClass?: string; // 自定义 class
  customStyle?: Record<string, string | number>; // 自定义 style
}

const props = defineProps<Props>();

const columns = computed(() => props.columns ?? 2);
const customClass = computed(() => props.customClass || "");
const customStyle = computed(() => props.customStyle || {});

// 动态计算容器类名
const containerClass = computed(() => ({
  "mx-base-info": true,
  [`layout-columns-${columns.value}`]: true,
  "has-border": props.showDashedBorder,
}));
</script>
<style lang="scss">
.mx-base-info {
  padding: 8px 0;
  border-radius: 2px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.layout-columns-1 > .row > * {
  flex: 1 1 100%;
}

.layout-columns-2 > .row > * {
  flex: 1 1 48%;
}

.layout-columns-3 > .row > * {
  flex: 1 1 30%;
}

.slot-item {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  column-gap: 8px;
}

.label {
  color: var(--mx-text-secondary-color);
}

.value {
  color: var(--mx-text-base-color);
}

.has-border {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}
</style>