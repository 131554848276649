<template>
  <ChartBase :option="option" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import dayjs from "dayjs";
import * as echarts from "echarts";

import { fetchEfficiency } from "@/api/inverter";
import { TimeValueResult } from "@/api/@types";

import np from "@/utils/number";

interface QueryType {
  deviceId: string;
  dateType: "minute" | "fifteen_minute" | "hour" | "day" | "month";
  dateRange: [string, string];
}

interface ChartProps {
  query: QueryType;
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateType: "hour",
    dateRange: ["", ""],
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>();
watch(
  () => props.query,
  () => {
    const query = props.query;
    //queryCache.querySet(query as any)
    if (
      !query.deviceId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = [];
      return;
    }
    loading.value = true;
    fetchEfficiency(query.deviceId, query.dateRange, query.dateType).then(
      list => {
        data.value = list;
        loading.value = false;
      },
    );
  },
  { immediate: true },
);

const times = computed(() => (data.value || []).map((d: any) => d.time));
const values = computed(() => (data.value || []).map((d: any) => d.value));

const tooltipFormatter = ([d]: Array<any>) => {
  const time = dayjs(d.name).format("YYYY-MM-DD HH:mm");
  return `<div style="margin-bottom:8px">${time}</div><span style="display:inline-block;margin-right:35px">逆变器效率</span> <span style="color:#4A90E2">${np(
    d.value,
  )} %</span>`;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: (data.value || []).length > 12,
    filterMode: "none",
  };
});
const option = ref({
  grid: {
    x: 40,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter,
  },
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "%",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: values,
      type: "line",
      smooth: true,
      symbol: "none",
      name: "逆变器效率",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#2cc4f9" },
          { offset: 1, color: "#0277bd" },
        ]),
      },
      barMaxWidth: "24px",
      barMinWidth: "2px",
    },
  ],
});
</script>
