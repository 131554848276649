<template>
  <ChartPower :data="data" :mode="props.query.dateType" v-loading="loading" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import debounce from "@/utils/debounce";
import { fetchPower } from "@/api/inverter";
import { TimeValueResult } from "@/api/@types";

interface QueryType {
  deviceId: string;
  dateType: "hour" | "day" | "month";
  dateRange: [string, string];
}

interface ChartProps {
  query: QueryType;
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateType: "hour",
    dateRange: ["", ""],
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>();
watch(
  () => props.query,
  () => {
    const query = props.query;
    //queryCache.querySet(query as any)
    if (
      !query.deviceId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = [];
      return;
    }
    loading.value = true;
    debounce("chart-inverter-power", () => {
      fetchPower(query.deviceId, query.dateRange, query.dateType).then(list => {
        data.value = list;
        loading.value = false;
      });
    });
  },
  { immediate: true },
);
</script>
