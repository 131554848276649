<template>
  <ChartLinevoltage :data="data" v-loading="loading" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { fetchLinevoltage } from "@/api/HVCabinet";
import { LineVoltageResult } from "@/api/@types";
import { debounce } from "lodash";
import { ChartProps } from "./common";

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateRange: ["", ""],
    dateType: "fifteen_minute",
  }),
});

const loading = ref<boolean>(false);
// 加载数据
const data = ref<LineVoltageResult>({ ab: [], bc: [], ca: [] });
watch(
  () => props.query,
  debounce(() => {
    const query = props.query;
    if (
      !query.deviceId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = { ab: [], bc: [], ca: [] };
      return;
    }
    loading.value = true;
    fetchLinevoltage(query.deviceId, query.dateRange).then(list => {
      data.value = list;
      loading.value = false;
    });
  }, 300),
  { immediate: true },
);
</script>
