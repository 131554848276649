import request from "@/utils/request";

export type AppConfigRes = Record<
  string,
  {
    pvId: number;
    pvName: string;
    kwunSn: string;
    pvStringName: string;
    pvInverterName: string;
    bracketName: string;
    weatherStationName: string;
  }
>;

export enum EndPointType {
  DEFAULT ='DEFAULT',
  OTHER = 'OTHER'
}

export const EndPointTypeValue = {
  [EndPointType.DEFAULT]: '起点',
  [EndPointType.OTHER]: '邻角',
};

export enum ClearAreaType {
  A='A',
  D='D',
  E='E'
}

export const ClearAreaTypeValue = {
  [ClearAreaType.A]: '全阵列',
  [ClearAreaType.D]: '单底边',
  [ClearAreaType.E]: '多底边',
};

export enum CleanStrengthType {
  Q='Q',
  N='N',
  D='D',
  S='S',
}

export const CleanStrengthTypeValue = {
  [CleanStrengthType.Q]: '快速清洁',
  [CleanStrengthType.N]: '正常清洁',
  [CleanStrengthType.D]: '深度清洁',
  [CleanStrengthType.S]: '重度清洁',
};

export enum CleanActionType {
  Immediate = 1,
  Today,
  Tomorrow,
}

export const CleanActionTypeValue = {
  [CleanActionType.Immediate]: '立即',
  [CleanActionType.Today]: '计算当日',
  [CleanActionType.Tomorrow]: '计算第二日',
};

// 操作日志
export const operateLogList = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/operate-log",
    params,
  );
};

// 支架状态
export const getBracketState = (): Promise<any> => {
  return request.post(
    "/v2/kwun/bracket/state",
  );
};
// 设置支架工作模式
export const setBracketWorkMode = (params: { workMode: number }): Promise<any> => {
  return request.post(
    "/v2/kwun/bracket/work-mode",
    params,
  );
};
// 机器人状态
export const getRobotState = (): Promise<any> => {
  return request.post(
    "/v2/kwun/robot/state",
  );
};
// 清洁配置详情
export const getCleanConfigInfo = (): Promise<any> => {
  return request.post(
    "/v2/kwun/clean-config/info",
  );
};
// 清洁配置修改
export const saveCleanConfig = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/clean-config/save",
    params
  );
};
// 清洁配置复位
export const resetCleanConfig = (): Promise<any> => {
  return request.post(
    "/v2/kwun/clean-config/rest",
  );
};
// 清洁记录分页
export const cleanLogList = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/clean-log",
    params,
  );
};
// 周期清洁策略详情
export const getRegularCleanStrategyInfo = (): Promise<any> => {
  return request.post(
    "/v2/kwun/regular-clean-strategy/info",
  );
};
// 周期清洁策略修改
export const saveRegularCleanStrategy = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/regular-clean-strategy",
    params,
  );
};
// 周期清洁策略启停
export const updateRegularState = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/regular-clean-strategy/state",
    params,
  );
};
// 智能清洁策略详情
export const getIntelligentCleanStrategyInfo = (): Promise<any> => {
  return request.post(
    "/v2/kwun/intelligent-clean-strategy/info",
  );
};
// 智能清洁策略修改
export const saveIntelligentCleanStrategy = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/intelligent-clean-strategy",
    params,
  );
};
// 智能清洁策略启停
export const updateIntelligentState = (params: any): Promise<any> => {
  return request.post(
    "/v2/kwun/intelligent-clean-strategy/state",
    params,
  );
};
// 开始清扫
export const startClean = (): Promise<any> => {
  return request.post(
    "/v2/kwun/start-clean",
  );
};
// 清除告警
export const clearAlarm = (): Promise<any> => {
  return request.post(
    "/v2/kwun/clear-alarm",
  );
};
// 应用配置信息
export const getAppConfig = (): Promise<AppConfigRes> => {
  return request.post(
    "/v2/kwun/app-config",
  );
};
// 场景类型
export const getSceneData = (): Promise<any> => {
  return request.post(
    "/v2/kwun/scene-data",
  );
};
// 工作模式列表
export const getWorkModeList = (): Promise<any> => {
  return request.post(
    "/v2/kwun/bracket/work-mode/list",
  );
};
// 获取pr值
export const getPrInfo = (): Promise<any> => {
  return request.post(
    "/v2/kwun/prValue",
  );
};
// 异常信息枚举
export const getWorkErrorList = (): Promise<any> => {
  return request.post(
    "/v2/kwun/work-error",
  );
};
