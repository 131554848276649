export default (value: any, dec = 2) => {
  // 如果 value 是 undefined、null、空字符串，直接返回 null
  if (value == null || value === "" || isNaN(value)) return null;

  // 尝试将 value 转换为数值
  const num = Number(value);

  // 如果转换后的值不是有限数字，返回 null
  if (!Number.isFinite(num)) return null;

  // 返回保留 dec 位小数的数字
  return Number(num.toFixed(dec));
};

export const toNumber = (value: number | string) => {
  if (!value) return 0;
  if (typeof value === "string") {
    if (value.trim() === "") return 0;
    value = Number(value);
  }
  if (value % 1 === 0) return value;
  return value.toFixed(2);
};

export const parseNumber = (value: string) =>
  parseFloat(value || "0").toFixed(2);
