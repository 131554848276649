<template>
  <ChartCo2 :data="data" v-loading="loading" :mode="props.query.dateType" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import debounce from "@/utils/debounce";
import { fetchCo2 } from "@/api/station";
import { TimeValueResult } from "@/api/@types";

type QueryType = {
  stationId: number;
  dateType: "hour" | "day" | "month";
  dateRange: [string, string];
};

interface ChartProps {
  query: QueryType;
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    stationId: 0,
    dateType: "hour",
    dateRange: ["", ""],
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>([]);
watch(
  () => props.query,
  () => {
    const query = props.query;
    if (
      !query.stationId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = [];
      return;
    }
    loading.value = true;
    debounce("chart-station-co2", () => {
      fetchCo2(query.stationId, query.dateRange, query.dateType).then(list => {
        data.value = list;
        loading.value = false;
      });
    });
  },
  { immediate: true },
);
</script>
