<template>
  <ChartElectricitySimple
    :data="data"
    :jump-data="jumpData"
    v-loading="loading"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { fetchElectricity } from "@/api/serialString";
import { TimeValueResult } from "@/api/@types";
import { ChartProps } from "./common";
import { debounce } from "lodash";

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateRange: ["", ""],
    dateType: "fifteen_minute",
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>([]);
const jumpData = ref<string[]>([]);
watch(
  () => props.query,
  debounce(() => {
    const query = props.query;
    if (
      !query.deviceId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = [];
      return;
    }
    loading.value = true;
    fetchElectricity(query.deviceId, query.dateRange, query.dateType).then(
      res => {
        data.value = res.itemVOList;
        jumpData.value = res.timeJumpList;
        loading.value = false;
      },
    );
  }, 300),
  { immediate: true },
);
</script>
