<template>
  <ChartOutletPower
    :data="data"
    :mode="props.query.dateType"
    v-loading="loading"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import debounce from "@/utils/debounce";
import { fetchGridPower } from "@/api/station";
import { GridResult } from "@/api/@types";

interface ChartProps {
  query: {
    stationId: number;
    dateType: "hour" | "day" | "month";
    dateRange: [string, string];
  };
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    stationId: 0,
    dateType: "hour",
    dateRange: ["", ""],
  }),
});

// 加载数据
const loading = ref(false);
const data = ref<GridResult>();
watch(
  () => props.query,
  () => {
    const query = props.query;
    if (
      !query.stationId ||
      query.dateRange.length !== 2 ||
      !query.dateRange?.[0] ||
      !query.dateRange?.[1]
    ) {
      data.value = {};
      return;
    }
    loading.value = true;
    debounce("chart-station-power", async () => {
      fetchGridPower(query.stationId, query.dateRange, query.dateType).then(
        res => {
          data.value = res;
          loading.value = false;
        },
      );
    });
  },
  { immediate: true },
);
</script>
