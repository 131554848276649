<template>
  <ChartBase :option="option" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import numberParse from "@/utils/number";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      a: [],
      b: [],
      c: [],
      timeJumpListA: [],
      timeJumpListB: [],
      timeJumpListC: [],
    }),
  },
});

const typeConfigs = {
  a: { title: "A相电流", color: "#fed618" },
  b: { title: "B相电流", color: "#30c25b" },
  c: { title: "C相电流", color: "#ff4b4a" },
};

const times = computed(() => props.data.a.map((d: any) => d.time));
const aValues = computed(() =>
  props.data.a.map((d: any) => numberParse(d.value)),
);
const bValues = computed(() =>
  props.data.b.map((d: any) => numberParse(d.value)),
);
const cValues = computed(() =>
  props.data.c.map((d: any) => numberParse(d.value)),
);

const aJump = computed(() =>
  (props.data.timeJumpListA ?? []).map((d: any) => [d, 0]),
);

const bJump = computed(() =>
  (props.data.timeJumpListB ?? []).map((d: any) => [d, 0]),
);

const cJump = computed(() =>
  (props.data.timeJumpListC ?? []).map((d: any) => [d, 0]),
);

const tooltipFormatter = ([a, b, c, aj]: Array<any>) => {
  if (aj?.seriesType === "scatter") {
    return "电流跳变";
  }
  const time = dayjs(a.name).format("YYYY-MM-DD HH:mm");
  const lists = [];

  if (a && null !== a.value && undefined !== a.value) {
    lists.push({
      title: typeConfigs.a.title,
      value: numberParse(a.value),
      color: typeConfigs.a.color,
    });
  }
  if (b && null !== b.value && undefined !== b.value) {
    lists.push({
      title: typeConfigs.b.title,
      value: numberParse(b.value),
      color: typeConfigs.b.color,
    });
  }
  if (c && null !== c.value && undefined !== c.value) {
    lists.push({
      title: typeConfigs.c.title,
      value: numberParse(c.value),
      color: typeConfigs.c.color,
    });
  }
  const listHtml = lists
    .map(
      d => `
    <div class="value-item">
      <label>${d.title}</label><span style="color:${d.color}">${d.value} A</span>
    </div>
  `,
    )
    .join("");
  if ("" === listHtml) return "";
  return `
    <div class="chart-tooltip">
      <div class="time">${time}</div>
      ${listHtml}
    </div>
  `;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    filterMode: "none",
  };
});

const serieItemCfg = {
  type: "line",
  smooth: true,
  symbol: "none",
  connectNulls: true,
  legendHoverLink: true,
};

const option = ref({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
    selectedMode: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter,
  },
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "A",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: aValues,
      name: typeConfigs.a.title,
      itemStyle: {
        color: typeConfigs.a.color,
      },
      ...serieItemCfg,
    },
    {
      data: bValues,
      name: typeConfigs.b.title,
      itemStyle: {
        color: typeConfigs.b.color,
      },
      ...serieItemCfg,
    },
    {
      data: cValues,
      name: typeConfigs.c.title,
      itemStyle: {
        color: typeConfigs.c.color,
      },
      ...serieItemCfg,
    },
    {
      data: aJump,
      symbolSize: 12,
      type: "scatter",
      itemStyle: {
        color: "#E64242",
        borderColor: "#fff",
      },
    },
    {
      data: bJump,
      symbolSize: 12,
      type: "scatter",
      itemStyle: {
        color: "#E64242",
        borderColor: "#fff",
      },
    },
    {
      data: cJump,
      symbolSize: 12,
      type: "scatter",
      itemStyle: {
        color: "#E64242",
        borderColor: "#fff",
      },
    },
  ],
});
</script>
